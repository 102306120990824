import { Message } from 'element-ui';

/* eslint-disable */
export default function (response, cb = function () {}) {
	return new Promise((resolve, reject) => {
		if (response.error) {
			const error_message = response.error.message;
			Message({
				message: `Failed. ${error_message}`,
				type: 'error',
			});
			cb();
			reject();
			return;
		}
		resolve();
	})
}
