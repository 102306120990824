<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="row row-deck row-cards mb-3">
        <div class="col-sm-6 col-lg-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="subheader">
                  <span>Today New Video</span>
                </div>
                <div class="ms-auto lh-1 subheader" :class="{ 'text-red': today_register < yesterday_register, 'text-green': today_register > yesterday_register }">
                   <font-awesome-icon :icon="icon_today_register"/> {{ today_new_progress }}%
                </div>
              </div>
              <div class="h1 mb-3"><b>{{today_register}}</b></div>
              <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="register_chart.options" :series="register_chart.series"/>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="subheader">
                  <span>Yesterday New Video</span>
                </div>
              </div>
              <div class="h1 mb-3"><b>{{yesterday_register}}</b></div>
              <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="register_chart.options" :series="register_chart.series"/>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <el-input v-if="search.search_by == 'academic_name'" clearable style="width: 150px" size="small" type="text" v-model="search.search_keyword" placeholder="Input search keyword" class="mr-2"/>
            <el-select v-if="search.search_by == 'academic_status'" size="small" v-model="search.search_keyword" class="mr-2" style="width: 150px">
              <el-option  value="1" label="active"/>
              <el-option  value="0" label="inactive"/>
            </el-select>
            <el-select size="small" v-model="search.search_by" class="mr-2" style="width: 150px" @change="ganti">
              <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
            </el-select>
            <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            <el-button type="primary" @click="doShowModalCreate" size="small"><font-awesome-icon icon="plus"/> Create new Academy</el-button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="50%">Name</th>
                <th>Category</th>
                <th>Type</th>
                <th>Price</th>
                <th >Thumbnail</th>
                <th >Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.academic_name }}</td>
                <td>{{ item.category.category_name }}</td>
                <td v-if=" item.academic_is_free == '1'" class="text-nowrap text-muted">Free</td>
                <td v-else-if=" item.academic_is_free == '0'" class="text-nowrap text-muted">Paid</td>
                <td v-else class="text-nowrap text-muted">Unknown</td>
                <td >
                    {{ item.academic_price_money_str }}
                </td>
                <td>
                  <span class="avatar" v-bind:style="{ backgroundImage: 'url(' + item.academic_logo + ')' }"></span>
                </td>
               <td><span class="tag" :class="item.status_color_str">{{ item.status_str }}</span></td>
               <td>
                 <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                  <a class="dropdown-item btn-sm cursor-pointer" @click="doShowModalEdit(item)" title="Edit promo" ><font-awesome-icon icon="pen" class="text-primary"/>&nbsp; Edit Academy</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="confirmActivateAcademy(item)"><font-awesome-icon class="text-yellow" icon="power-off"/>&nbsp; Enable/Disable Academy</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="confirmDeleteAcademy(item)" title="Delete Academy" ><font-awesome-icon icon="trash" class="text-danger"/>&nbsp; Delete Academy</a>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
        <b-modal v-model="showModalCreate" :title="modalTitle" hide-footer>
          <b-form @submit.prevent="createNewAcademy" @reset="closeModalCreate">
            <b-form-group label="Name">
              <b-input
                v-model="v$.form.academic_name.$model"
                placeholder="Input academic name"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.academic_name.$invalid" v-if="v$.form.academic_name.$errors[0]">
                <span> {{ v$.form.academic_name.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Logo">
              <input  @change="handleImage"
              class="form-control" type="file" accept="image/*">
              <!--
              <el-input
                accept="image/*"
                change="handleImage"
                v-model="v$.form.academic_logo.$model"
                placeholder="Input academic logo"
                type="file"/>
              -->
              <b-form-invalid-feedback :state="!v$.form.academic_logo.$invalid" v-if="v$.form.academic_logo.$errors[0]">
                <span> {{ v$.form.academic_logo.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Video">
              <b-input
                v-model="v$.form.academic_video.$model"
                placeholder="Input academic video"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.academic_video.$invalid" v-if="v$.form.academic_video.$errors[0]">
                <span> {{ v$.form.academic_video.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Desc">
              <b-input
                v-model="v$.form.academic_text.$model"
                placeholder="Input academic description"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.academic_text.$invalid" v-if="v$.form.academic_text.$errors[0]">
                <span> {{ v$.form.academic_text.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Category">
              <el-select
                class="w-full"
                v-model="v$.form.category_id.$model"
                placeholder="Select academic category"
                v-loading="isLoadingCategorys">
                <el-option v-for="(item, index) in categoryList" :value="item.category_id" :key="index" :label="item.category_name"/>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.category_id.$invalid" v-if="v$.form.category_id.$errors[0]">
                <span> {{ v$.form.category_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Duration (in second)">
              <b-input
                v-model="v$.form.academic_duration.$model"
                placeholder="Input academic duration"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.academic_duration.$invalid" v-if="v$.form.academic_duration.$errors[0]">
                <span> {{ v$.form.academic_duration.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Min Duration (in second)">
              <b-input
                v-model="v$.form.academic_minimal_duration.$model"
                placeholder="Input academic duration"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.academic_minimal_duration.$invalid" v-if="v$.form.academic_minimal_duration.$errors[0]">
                <span> {{ v$.form.academic_minimal_duration.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Free">
              <el-checkbox value='0' id='0'
              v-model="v$.form.academic_is_free.$model"
              label="Free"
              true-label='1'
              false-label='0'></el-checkbox>
              <b-form-invalid-feedback :state="!v$.form.academic_is_free.$invalid" v-if="v$.form.academic_is_free.$errors[0]">
                <span> {{ v$.form.academic_is_free.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Price">
              <b-input
                v-model="v$.form.academic_price_money.$model"
                placeholder="Input academic price"
                type="number"/>
              <b-form-invalid-feedback :state="!v$.form.academic_price_money.$invalid" v-if="v$.form.academic_price_money.$errors[0]">
                <span> {{ v$.form.academic_price_money.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="d-flex flex-row float-right">
              <el-button @click="confirmCreateAcademy" :loading="loading.create_academy" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
              <el-button @click="closeModalCreateAcademy" size="small" class="mr-2">Cancel</el-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
</template>
<script>
import { orderBy } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import {
  GET_ACADEMYS,
  CREATE_ACADEMYS,
  UPDATE_ACADEMY,
  ANALYTIC_ACADEMYS,
} from '@/store/modules/academys';
import { GET_CATEGORYS } from '@/store/modules/categorys';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'Academy',
  metaInfo: {
    title: 'Academy',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      isLoadingCategorys: false,
      loader: null,
      // search_keyword: '',
      // search_by: '',
      search_merchant_keyword: '',
      loading: {
        create_academy: false,
      },
      showModalCreate: false,
      modalTitle: this.modalAction === 'add' ? 'Create new Academy' : 'Edit Academy',
      form: {
        academic_name: '',
        academic_logo: '',
        academic_video: '',
        academic_text: '',
        category_id: '',
        academic_duration: '',
        academic_minimal_duration: '',
        academic_is_free: '0',
        academic_price_money: '',
        academic_status: 1,
      },
      categoryList: [],
      search: {
        search_keyword: '',
        search_by: '',
      },
      analytic: {},
      today_register: 0,
      yesterday_register: 0,
      today: moment().format('YYYY-MM-DD'),
      yest: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      register_chart: {
        options: {
          chart: {
            type: 'area',
            fontFamily: 'inherit',
            height: 40.0,
            sparkline: {
              enabled: true,
            },
            animations: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 0.16,
            type: 'solid',
          },
          stroke: {
            width: 2,
            lineCap: 'round',
            curve: 'smooth',
          },
          xaxis: {
            labels: {
              padding: 0,
            },
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories: [],
          },
          yaxis: {
            labels: {
              padding: 4,
            },
          },
          labels: [],
          legend: {
            show: false,
          },
        },
        series: [],
      },
      search_options: [
        { value: 'academic_name', text: 'Name' },
        { value: 'academic_status', text: 'Status' },
      ],
    };
  },
  validations() {
    return {
      form: {
        academic_name: {
          required,
        },
        academic_logo: {
          required,
        },
        academic_video: {
          required,
        },
        academic_text: {
          required,
        },
        category_id: {
          required,
        },
        academic_duration: {
          required,
        },
        academic_minimal_duration: {
          required,
        },
        academic_is_free: {},
        academic_price_money: {
          // required,
          required: requiredIf(this.form.academic_is_free === '0'),
        },
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    icon_today_register() {
      if (this.today_register < this.yesterday_register) {
        return 'chevron-down';
      }
      if (this.today_register > this.yesterday_register) {
        return 'chevron-up';
      }
      return 'minus';
    },
    icon_yesterday_register() {
      if (this.today_register > this.yesterday_register) {
        return 'chevron-down';
      }
      if (this.today_register < this.yesterday_register) {
        return 'chevron-up';
      }
      return 'minus';
    },
    icon_today_login() {
      if (this.today_login < this.yesterday_login) {
        return 'chevron-down';
      }
      if (this.today_login > this.yesterday_login) {
        return 'chevron-up';
      }
      return 'minus';
    },
    icon_yesterday_login() {
      if (this.today_login > this.yesterday_login) {
        return 'chevron-down';
      }
      if (this.today_login < this.yesterday_login) {
        return 'chevron-up';
      }
      return 'minus';
    },
    today_new_progress() {
      if (this.yesterday_register > 0) {
        return ((this.today_register - this.yesterday_register) / this.yesterday_register).toFixed(3) * 100;
      }
      return 0;
    },
    today_login_progress() {
      if (this.yesterday_login > 0) {
        console.log('login', this.today_login - this.yesterday_login, ((this.today_login - this.yesterday_login) / this.yesterday_login));
        return ((this.today_login - this.yesterday_login) / this.yesterday_login).toFixed(3) * 100;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    await this.getAnalytic();
    loader.hide();
  },
  methods: {
    onChange(event) {
      console.log(event.target.value);
      this.search.search_by = event.target.value;
    },
    ganti(event) {
      console.log('test', event);
      this.search.search_keyword = '';
      this.search.search_by = event;
    },
    hideshowdiv(value) {
      console.log('val', value);
      // this.search.search_by = value;
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search_by = this.search.search_by;
      // this.search_by = 'academic_name';
      this.search_keyword = this.search.search_keyword;
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_ACADEMYS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.academys;
      this.total_rows = count || 0;
      this.list = rows;
      this.isLoading = false;
    },
    async getCategoryList() {
      this.isLoadingCategorys = true;
      await this.$store.dispatch(GET_CATEGORYS, {
        search_by: 'category_type',
        search_keyword: '2',
      }).catch(() => {});
      const { rows, count } = this.$store.getters.categorys;
      this.total_rows = count || 0;
      this.categoryList = rows;
      this.isLoadingCategorys = false;
    },
    doShowModalCreate() {
      this.v$.form.$touch();
      this.modalType = 'add';
      this.showModalCreate = true;
      this.getCategoryList();
    },
    closeModalCreate() {
      this.showModalCreate = false;
    },
    confirmCreateAcademy() {
      if (this.modalType === 'edit') {
        this.doUpdateAcademy();
        return;
      }
      this.$confirm('You will create a new promo. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.doCreateAcademy();
          }
          done();
        },
      }).catch(() => {});
    },
    closeModalCreateAcademy() {
      this.form = {
        academic_name: '',
        academic_logo: '',
        academic_video: '',
        academic_text: '',
        category_id: '',
        academic_duration: '',
        academic_minimal_duration: '',
        academic_is_free: '0',
        academic_price_money: '',
        academic_status: 1,
      };
      this.showModalCreate = false;
    },
    async doCreateAcademy() {
      this.loading.create_academy = true;
      await this.$store.dispatch(CREATE_ACADEMYS, this.form)
        .then(() => {
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Academy created',
          });
          this.closeModalCreateAcademy();
          this.getList();
        }).catch((err) => {
          popupErrorMessages(err.response.data);
        });
      this.loading.create_academy = false;
    },
    doShowModalEdit(item) {
      this.getCategoryList();
      this.form = {
        id: item.academic_id,
        academic_name: item.academic_name,
        academic_logo: item.academic_logo,
        academic_video: item.academic_video,
        academic_text: item.academic_text,
        category_id: item.category_id,
        academic_duration: item.academic_duration,
        academic_minimal_duration: item.academic_minimal_duration,
        academic_is_free: item.academic_is_free ? item.academic_is_free.toString() : '0',
        academic_price_money: item.academic_price_money,
        academic_status: 1,
      };
      this.v$.form.$touch();
      this.modalType = 'edit';
      this.showModalCreate = true;
    },
    async doUpdateAcademy() {
      this.$confirm('You will update this academy. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.loading.create_academy = true;
            await this.$store.dispatch(UPDATE_ACADEMY, this.form)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Academy updated',
                });
                this.showModalCreate = false;
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            this.loading.create_academy = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async confirmDeleteAcademy(item) {
      this.form = {
        id: item.academic_id,
        academic_name: item.academic_name,
        academic_logo: item.academic_logo,
        academic_video: item.academic_video,
        academic_text: item.academic_text,
        category_id: item.category_id,
        academic_duration: item.academic_duration,
        academic_minimal_duration: item.academic_minimal_duration,
        academic_is_free: item.academic_is_free.toString(),
        academic_price_money: item.academic_price_money,
        academic_status: 0,
      };
      this.$confirm('You will delete this Academy. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_ACADEMY, this.form)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Academy deleted',
                });
                this.getList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
            instance.confirmButtonLoading = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    async confirmActivateAcademy(item) {
      let msg = 'You will activate this Academy. Continue?';
      if (item.academic_status === 1) {
        msg = 'You will inactivate this Academy. Continue?';
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(UPDATE_ACADEMY, {
              id: item.academic_id,
              academic_status: item.academic_status === 1 ? 0 : 1,
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Academy successfully updated',
              });
              this.getList();
            }).catch((err) => {
              popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
            done();
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.academic_logo = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    async getAnalytic() {
      this.isLoading = true;
      await this.$store.dispatch(ANALYTIC_ACADEMYS, {
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
      }).catch(() => {});

      this.analytic = this.$store.getters.analytic_academy;
      this.today_register = this.analytic.new_video_daily.find((v) => v.created_at === this.today)?.count || 0;
      this.yesterday_register = this.analytic.new_video_daily.find((v) => v.created_at === this.yest)?.count || 0;

      const obj = orderBy(this.analytic.new_video_daily, 'created_at').reverse().splice(0, 30).reverse();
      this.register_chart.series = [{
        name: 'Daily New Video',
        data: obj.map((v) => v.count),
      }];
      this.register_chart.options.labels = obj.map((v) => v.created_at);
      this.register_chart.options.xaxis.categories = obj.map((v) => v.created_at);

      this.isLoading = false;
    },
  },
};
</script>
